@font-face {
    font-family: "Eurostile Bold";
    src: url("./content/fonts/EurostileBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Eurostile Extended";
    src: url("./content/fonts/EurostileExtended.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Eurostile Oblique";
    src: url("./content/fonts/EurostileOblique.ttf") format("truetype");
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: "EuroStyle Normal";
    src: url("./content/fonts/EuroStyle Normal.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Eurostile Bold";
}

main {
    padding-bottom: 350px;
}

.text-seqtor {
    font-family: "Eurostile Bold";
}

.carousel-caption.custom-shape {
    margin: auto;
    clip-path: polygon(5% 0%, 100% 0%, 100% 70%, 95% 100%, 0% 100%, 0% 30%);
    background-color: var(--bs-dark) !important;
    border-radius: unset !important;
    border: none;
    width: 600px;
    color: var(--bs-light) !important;
}

article {
    .card.custom-shape {
        clip-path: polygon(10% 0%, 100% 0%, 100% 90%, 90% 100%, 0% 100%, 0% 10%);
        background-color: var(--bs-secondary-bg);
        border-radius: unset !important;
        border: none;
        min-width: 350px;
    }
}

.card.custom-shape {
    clip-path: polygon(10% 0%, 100% 0%, 100% 90%, 90% 100%, 0% 100%, 0% 10%);
    background-color: var(--bs-secondary-bg);
    border-radius: unset !important;
    border: none;
    min-width: 350px;
}

footer {
    font-size: .8rem;
    padding: 5px;

    span {
        font-family: Eurostile Bold;
    }
}

// Gallery

.gallery-container {
    max-height: 80vh;
    /* Adjusts based on viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge (Legacy) */
}

/* Reels Container */
.reel-container {
    max-height: 80vh;
    /* Adjusts based on viewport height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge (Legacy) */
}

.reel-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Chrome, Safari, and newer Edge */
}

/* Root Theme Variables */
:root {
    --reel-bg: #fff;
    --reel-border: #ddd;
    --reel-shadow: rgba(0, 0, 0, 0.1);
    --reel-text: #000;
}

[data-bs-theme="dark"] {
    --reel-bg: #1c1c1e;
    --reel-border: #333;
    --reel-shadow: rgba(255, 255, 255, 0.1);
    --reel-text: #fff;
}

/* Reel Styling */
.reel {
    width: 60%;
    max-width: 90%;
    /* Ensure responsiveness */
    margin: auto;
    margin-bottom: 1rem;
    border: 1px solid var(--reel-border);
    border-radius: 8px;
    overflow: hidden;
    background: var(--reel-bg);
    box-shadow: 0 4px 8px var(--reel-shadow);
    color: var(--reel-text);
}

.reel .media img,
.reel .media video {
    width: 100%;
    /* Ensure media fits within the container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Adapt content to fit */
}

.reel .content {
    padding: 1rem;
}

.reel .buttons {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-top: 1px solid var(--reel-border);
}

.reel .buttons button {
    flex: 1;
    margin: 0 0.5rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

    .gallery-container {
        max-height: none;
        /* Remove height limitation */
        overflow: visible;
        /* Allow the content to expand fully */
    }

    .reel-container {
        max-height: none;
        /* Remove height limitation */
        overflow: visible;
        /* Allow the content to expand fully */
    }

    .reel {
        width: 100%;
        /* Ensure reels take up full width */
        max-width: none;
        /* Remove width limitation */
        margin-bottom: 2rem;
        /* Add spacing between reels */
    }

    .reel .media img,
    .reel .media video {
        width: 100%;
        /* Ensure media scales with the container */
        height: auto;
        /* Maintain aspect ratio */
    }

    .row {
        flex-direction: column;
        /* Stack all columns vertically */
    }

    .row>.col-lg-4:nth-child(2) {
        order: -1;
        /* Move the middle column to the top */
        height: 100vh;
        /* Occupy full viewport height */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center content vertically */
        align-items: center;
        /* Center content horizontally */
    }

    .row>.col-lg-4 {
        order: 0;
        /* Reset order for other columns */
        height: auto;
        /* Adjust height for remaining columns */
    }
}