.message-history {
    max-height: 400px;
    overflow-y: auto;
    padding: 12px;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.5rem;

  // Scrollbar styling
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute; // Makes it overlap with the border
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 10px;
    border: 2px solid transparent; // Ensure spacing around the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0b0b0;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #bfbfbf;
  }

  // Adapt to light and dark themes
  &[data-bs-theme="light"] {
    border-color: #ddd;

    &::-webkit-scrollbar-thumb {
      background-color: #c0c0c0;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a0;
    }
  }
}

.message-bubble {
    max-width: 70%;
    word-wrap: break-word;
    padding: 10px;
    border-radius: 20px; // Rounded corners for bubble effect
    margin-bottom: 10px; // Add some margin between bubbles
}

// Message bubble for owner
.owner-message {
    background-color: var(--bs-light-bg); // Light background for the owner in dark mode
    color: var(--bs-body-color);
    border: 1px solid var(--bs-border-color); // Optional border for better separation
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); // Optional shadow for bubble effect
}

// Message bubble for user
.user-message {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    border: 1px solid var(--bs-primary);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); // Optional shadow for bubble effect
}