.product-details {
    font-family: 'Arial', sans-serif;
  
    .hero-image {
      position: relative;
      width: 100%;
      height: 400px;
      background-size: cover;
      background-position: center;
  
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
  
        .product-name {
          color: white;
          font-size: 3rem;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        }
      }
    }
  
    .container {
      .row {
        margin-top: 5rem;
  
        .col-md-8, .col-md-4 {
          h2 {
            margin-bottom: 1.5rem;
          }
  
          ul {
            list-style: none;
            padding: 0;
  
            li {
              strong {
                font-weight: bold;
              }
            }
          }
        }
  
        .col-md-8 {
          p {
            margin-bottom: 2rem;
          }
        }
      }
    }
  
    .performance-chart {
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 5rem;
  
      h3 {
        margin-bottom: 2rem;
      }
    }
  
    .pricing-section {
      padding: 4rem 0;
      background: linear-gradient(135deg, #007bff, #0056b3);
      color: white;
      text-align: center;
      margin-top: 5rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      
      .price {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 2rem;
        line-height: 1.2;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      }
  
      .btn-primary {
        background-color: #28a745;
        border-color: #28a745;
        font-size: 1.25rem;
        padding: 0.75rem 1.5rem;
        border-radius: 50px;
        transition: background-color 0.3s, box-shadow 0.3s;
  
        &:hover {
          background-color: #218838;
          border-color: #1e7e34;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  