@keyframes zoomPan {
  0% {
    transform: scale(1) translateX(0);
  }
  25% {
    transform: scale(1.1) translateX(-5%);
  }
  50% {
    transform: scale(1.1) translateX(5%);
  }
  75% {
    transform: scale(1.1) translateX(-5%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

.carousel-item img.slider-item {
  object-fit: cover;
  object-position: center;
  animation: zoomPan 60s infinite ease-in-out; /* Add combined zoom and pan animation */
}
